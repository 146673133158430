import React from 'react';
import {
  Formik, Form, FormikProps,
  FormikHelpers as FormikActions,
} from 'formik';
import {
  Button,
  ButtonTitle,
  ChangePasswordFields,
  ChangePasswordFormValues,
  ChangePasswordSchema,
  SubTitle,
} from '@adac/core-view';
import { __ } from '@adac/core-model';
import {
  LayoutStyles,
} from './Profile';
import { FormProps } from '../common/react-admin/interfaces';


export interface ChangePasswordProps extends FormProps{
  onSubmit: (values: ChangePasswordFormValues, actions: FormikActions<any>) => void;
}
export const ChangePassword = ({ onSubmit, ...raProps }: ChangePasswordProps) => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  return (
    <LayoutStyles>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }: FormikProps<ChangePasswordFormValues>) => (
          <Form>
            <SubTitle>{__('Credentials')}</SubTitle>

            <ChangePasswordFields />

            <fieldset>
              <Button info type="button" onClick={() => raProps.history.goBack()}>
                <ButtonTitle>{__('Cancel')}</ButtonTitle>
              </Button>
              <Button
                cta
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid}
                title={__('Save')}
              />
            </fieldset>
          </Form>
        )}
      </Formik>
    </LayoutStyles>
  );
};
