import { getApiRoutes, User } from '@adac/core-model';
import { request } from '@adac/core-view';

export function getBlockedList(token: string | null) {
  const url = getApiRoutes().backoffice.users.listBlocked;
  return request<User[]>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token || ''}`,
    },
  });
}

export function unblockUser(userId: number, token: string | null) {
  const url = getApiRoutes().auth.users.unblock(userId);
  return request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token || ''}`,
    },
  });
}
