import {
  config, getApiRoutes, RegionRecord, __,
} from '@adac/core-model';
import { request, useAsyncAction } from '@adac/core-view';
import { stores } from '../stores';

const { apiTimeout } = config.location();

// NOTE: this function is used with useAsyncAction, so try-catch is handled in that
async function getRegionList() {
  const url = getApiRoutes().backoffice.region.list;
  return request<RegionRecord[]>(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${stores.auth.token || ''}`,
    },
    timeout: apiTimeout,
  });
}

export function useRegionList() {
  const { resource, ...result } = useAsyncAction(getRegionList);
  return {
    ...result,
    regions: [{ id: null, name: __('ALL REGION'), zips: [] }, ...(resource || [])] as RegionRecord[],
  };
}
