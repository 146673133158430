/* eslint-disable @typescript-eslint/no-explicit-any */
import { getApiRoutes } from '@adac/core-model';
import { getOptions } from './requestCreators';

import { handleResponseStatus } from './responseHandlers';

interface CreateCaseCommentData {
  caseId: number;
  userId: number;
  text: string;
}

export const createCaseComment = async (token: string, data: CreateCaseCommentData) => {
  const url = getApiRoutes().backoffice.case.comment(token);

  const newOptions = {
    ...getOptions(),
    method: 'POST',
    body: JSON.stringify({ ...data }),
  };

  const response = await window.fetch(url, newOptions);
  const result = await handleResponseStatus(response);

  return result;
};
