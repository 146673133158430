import React from 'react';
import { __, DriverInfo } from '@adac/core-model';
import { Loader, Text } from '@adac/core-view';
import BlockIcon from '@material-ui/icons/Block';
import styled from 'styled-components';


import CaseStatusInfoBlock from './CaseStatusInfoBlock';

const NoDataStyles = styled(Text)`
  svg {
    vertical-align: text-bottom;
    margin-right: 6px;
  }
`;

interface NoDataProps {
  message: string;
}

export const NoData = ({ message }: NoDataProps) => <NoDataStyles><BlockIcon />{__(message)}</NoDataStyles>;

interface CaseStatusDetailsProps {
  isLoading?: boolean;
  driverInfo?: DriverInfo;
}


export const CaseDriverInfo = ({ isLoading, driverInfo }: CaseStatusDetailsProps) => (
  <Loader isLoading={!driverInfo && isLoading === false} component={() => <NoData message="Driver was not set" />}>
    <CaseStatusInfoBlock title={__('Driver ID')}><Loader isLoading={isLoading}>{driverInfo?.driver?.id}</Loader></CaseStatusInfoBlock>
    <CaseStatusInfoBlock title={__('Driver name')}><Loader isLoading={isLoading}>{driverInfo?.driver?.firstName} {driverInfo?.driver?.familyName}</Loader></CaseStatusInfoBlock>
    <CaseStatusInfoBlock title={__('Driver phone number')}><Loader isLoading={isLoading}>{driverInfo?.driver?.publicPhone}</Loader></CaseStatusInfoBlock>
    <CaseStatusInfoBlock title={__('Estimation')}><Loader isLoading={isLoading}>{driverInfo?.estimation} {__('Minute')}</Loader></CaseStatusInfoBlock>
  </Loader>
);
