import { BackofficeUser } from '@adac/core-model';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { getBackofficeUser } from '@adac/core-view';
import StoresContext from '../stores';

export function useMe() {
  const { auth: { token } } = useContext(StoresContext);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<BackofficeUser | undefined>(undefined);

  const fetchUser = useCallback(async () => {
    if (!token) {
      console.error('token not found for user');
      setIsLoading(false);
      return;
    }

    try {
      const me = await getBackofficeUser(token);
      setUser(me);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);


  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return {
    user,
    isLoading,
  };
}
