import styled from 'styled-components';
import Card from '@material-ui/core/Card';

import { View } from '@adac/core-view';

export default styled(Card)`
  display: grid;
  place-items: center;
`;

export const CardContent = styled(View)`
  min-width: 508px;
  width: 33%;
  margin-bottom: 40px;  
`;
