import React from 'react';
import { UserRole, hasUserRole } from '@adac/core-model';
import {
  EditButton,
} from 'react-admin';
import { useUserRole } from '../../../helpers/useUserRole';

type EditButtonProps = any; // @types/react-admin shall be better.

interface EditButtonWithRoleProps extends EditButtonProps {
  role?: UserRole;
}

export const EditButtonWithRole = ({ role, ...props }: EditButtonWithRoleProps) => {
  const userRole = useUserRole();
  const editRole = role || UserRole.MANAGER;
  if (hasUserRole(userRole.names, editRole)) return <EditButton {...props} />;
  return null;
};
