import React from 'react';
import { __, Company, getCompanyId } from '@adac/core-model';


import { Loader } from '@adac/core-view';
import CaseStatusInfoBlock from './CaseStatusInfoBlock';
import { NoData } from './CaseDriverInfo';

interface CaseStatusDetailsProps {
  isLoading?: boolean;
  companyInfo?: Company;
}

export const CaseCompanyInfo = ({ isLoading, companyInfo }: CaseStatusDetailsProps) => (
  <Loader isLoading={!companyInfo && isLoading === false} component={() => <NoData message="Company was not set" />}>
    {companyInfo && <CaseStatusInfoBlock title={__('Company ID')}><Loader isLoading={isLoading}>{getCompanyId(companyInfo)}</Loader></CaseStatusInfoBlock>}
    <CaseStatusInfoBlock title={__('Company')}><Loader isLoading={isLoading}>{companyInfo?.name}</Loader></CaseStatusInfoBlock>
  </Loader>
);
