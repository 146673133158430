import React from 'react';
import { GET_ONE } from 'react-admin';
import dataProvider from '.';


export function useDataProviderRecord<DataType>(resource: string, id?: number) {
  const [record, setRecord] = React.useState<DataType | null>(null);

  React.useEffect(() => {
    if (id && resource) {
      (async () => {
        const d = await dataProvider(GET_ONE, resource, { id });
        setRecord(d.data);
      })();
    }
  }, [id, resource]);

  return record;
}
