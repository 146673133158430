import React from 'react';
import {
  MenuItem, FormControl, Input, Select, Chip, TextField as MuiTextField, makeStyles, createStyles, Theme, InputLabel,
} from '@material-ui/core';


import { __ } from '@adac/core-model';

import {
  Field, FieldProps, useFormikContext,
} from 'formik';
import { useRegionList } from '../../dataProvider/useRegionList';
import { HolidayFormValues } from './HolidayFormValues';


export const SimpleRegionSelector = () => {
  const { setFieldValue, values } = useFormikContext<HolidayFormValues>();
  const { regions } = useRegionList();

  return (
    <Field
      name="regionNames"
      render={({ field }: FieldProps<'regionNames', HolidayFormValues>) => (
        <MuiTextField
          select
          label={__('Related region')}
          variant="outlined"
          {...field}
          onChange={e => setFieldValue('regionNames', [e.target.value])}
        >
          {regions && regions
            .map(region => (
              <MenuItem
                key={region.name}
                selected={region.name === values?.regionNames?.[0]}
                value={region.name}
              >
                {region.name}
              </MenuItem>
            ))}
        </MuiTextField>
      )}
    />
  );
};


const useStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleRegionSelector = (): JSX.Element => {
  const { regions } = useRegionList();
  const classes = useStyles();

  const getNameForRegionId = (name: string) => {
    const region = regions.find(r => r.name?.toString() === name.toString());
    if (region) {
      return region?.name;
    }
    return null;
  };

  return (
    <Field
      name="regionNames"
      render={({ field }: FieldProps<'regionNames', HolidayFormValues>) => (
        <FormControl className={classes.formControl}>
          <InputLabel>{__('Related region')}</InputLabel>
          <Select
            id="regionNames"
            multiple
            title={__('Related region')}
            input={<Input />}
            renderValue={selected => (
              <div className={classes.chips}>
                {(selected as string[])?.map(value => (
                  <Chip key={value} label={getNameForRegionId(value)} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            {...field}
            value={field.value || []}
          >
            {regions && regions.map(region => (
              <MenuItem
                key={region.name}
                value={region.name}
              >
                {region.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
