import React, { useState } from 'react';

import { Button } from '@adac/core-view';

import { ChildrenProps } from '@adac/core-model';
import ListActionPopup, { ListActionPopupProps, ListActionItemComponent } from './ListActionPopup';

interface Props extends ListActionPopupProps, ChildrenProps {
  id: number;
  icon: JSX.Element;
}

export default ({
  id, rowHeight, items, icon, ...props
}: Props) => {
  const [showActionsForId, setShowActionsForId] = useState<number | null>(null);

  const visibleItems = items.filter(item => item.visible);

  const onActionsClicked = (idClicked: number) => {
    setShowActionsForId(showActionsForId === idClicked ? null : idClicked);
  };

  const ListActionPopupButton = () => (
    <Button onClick={() => onActionsClicked(id)}>
      <>
        {icon}
        {showActionsForId === id && (
          <ListActionPopup
            rowHeight={rowHeight}
            items={items}
            {...props}
          />
        )}
      </>
    </Button>
  );

  if (visibleItems.length === 1) return <ListActionItemComponent {...visibleItems[0]} />;
  if (visibleItems.length > 1) return <ListActionPopupButton />;
  return null;
};
