import React from 'react';
import { isUserRoleReadOnly } from '@adac/core-model';
import StoresContext from '../stores';


export const useUserRole = () => {
  const { auth: authStore } = React.useContext(StoresContext);
  return {
    names: authStore.userRole,
    readonly: isUserRoleReadOnly(authStore.userRole),
  };
};

export const useIsUserReadOnly = () => {
  const role = useUserRole();
  return isUserRoleReadOnly(role.names);
};
