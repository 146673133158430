/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Filter,
  TextInput,
  BooleanInput,
} from 'react-admin';
import {
  __,
} from '@adac/core-model';

export default (props: any) => (
  <Filter {...props}>
    <TextInput label={__('Partner ID')} source="id" allowEmpty />
    <TextInput label={__('Contact Manager ID')} source="contactManagerId" allowEmpty />
    <BooleanInput label={__('Hide inactive')} source="active" alwaysOn />
    {/* // TODO: We shall have either a DropDown with values or text filter  */}
    <BooleanInput label={__('Only CompanyType:SND ')} source="onlySND" />
    <TextInput label={__('Company Name')} source="name" allowEmpty />
    {/* // zip:TODO2: add zipCode filter */}
    {/* <TextInput label={__('Zip Code')} source="zipCode" allowEmpty /> */}
    <TextInput label={__('Company Boss')} source="dispatcherName" allowEmpty />
    <TextInput label={__('Mobile phonenumber')} source="privatePhoneUrl" allowEmpty />
    {/* <TextInput label={__('Name')} source="dispatcherName" allowEmpty /> */}
    {/* <TextInput label={__('Role')} source="role" allowEmpty /> */}
  </Filter>
);
