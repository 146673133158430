/* eslint-disable import/no-extraneous-dependencies */
import {
  List, showNotification as showNotificationAction, TextField, Datagrid, DatagridBody, ShowButton,
} from 'react-admin';
import React from 'react';
import { connect } from 'react-redux';
import { __, AdminLogRecord } from '@adac/core-model';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router';
import { themeConfig } from '@adac/core-view';
import { PostPagination } from '../snd/CompanyList';
import AdminLogFilter from './AdminLogFilter';
import { GenericListActions } from '../common/GenericListActions';
import { RARecordProps } from '../common/react-admin/interfaces';


interface AdminLogListProps {
  [key: string]: any;
}


const StyledStatus = styled(TextField)<RARecordProps<AdminLogRecord>>`
  border-radius: 8px;
  background-color: ${props => ((props?.record?.status < 200 || props?.record?.status > 299) ? themeConfig.colors.warning : themeConfig.colors.progress)};
  color: ${themeConfig.colors.background} !important;
  }};
`;

const AdminLogDatagridRow = ({
  record, resource, id, children, basePath, history,
}: any) => {
  const rowHeight = 60;

  const logDetailPage = (logId: string) => {
    history.push(`adminlog/${logId}/show`);
  };

  return (
    <TableRow
      key={id}
      style={{
        height: `${rowHeight}px`,
        cursor: 'pointer',
      }}
      hover
    >
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell
          key={`${id}-${field.props.source}`}
          onClick={() => logDetailPage(record.id)}
          style={{ padding: '8px', whiteSpace: 'pre-wrap', textAlign: 'center' }}
        >
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}

    </TableRow>
  );
};

const ConnectedAdminLogRow = connect(null, {
  showNotification: showNotificationAction,
})(withRouter(AdminLogDatagridRow));


const AdminLogDataGridRow = (props: any) => (
  <DatagridBody
    {...props}
    row={(<ConnectedAdminLogRow />)}
  />
);
const AdminLogDataGrid = (props: any) => <Datagrid {...props} body={<AdminLogDataGridRow />} />;

const AdminLogList = ({ ...props }: AdminLogListProps) => (
  <List
    {...props}
    bulkActionButtons={false}
    actions={<GenericListActions allowCreate={false} />}
    filters={<AdminLogFilter />}
    pagination={<PostPagination />}
  >
    <AdminLogDataGrid>
      <TextField source="id" label={__('ID')} />
      {/* <TextField source="commissioningReference" label={__('Commissioning')} /> */}
      <TextField source="entity" label={__('Entity')} />
      <TextField source="entityId" label={__('EntityId')} />
      <TextField source="action" label={__('Action')} />
      <TextField source="userId" label={__('User ID')} />
      <TextField source="userName" label={__('Username')} />
      <StyledStatus source="status" label={__('Status')} />
      <TextField source="url" label={__('Url')} sortable={false} />
      <TextField source="createdAt" label={__('createdAt')} sortable={false} />
      <ShowButton label={__('Show All')} />
    </AdminLogDataGrid>
  </List>
);

export default connect(null, {
  showNotification: showNotificationAction,
})(AdminLogList);
