

import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { AuthenticationMode, AvailableNotificationSettingValues as Channel } from '@adac/core-model';
import StoresContext from '../stores';
import { getSignInUserSetting } from '../networking/settings';

const initialSettings = {
  [AuthenticationMode.SIGN_IN_CHANNEL]: Channel.SMS,
};

export function useSettings(userId: number) {
  const { auth: { token } } = useContext(StoresContext);
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(initialSettings);

  const fetchSettings = useCallback(async () => {
    if (!token) {
      console.error('token not found for user');
      setIsLoading(false);
      return;
    }

    try {
      const settings = await getSignInUserSetting(userId, token);
      if (settings[AuthenticationMode.SIGN_IN_CHANNEL]) {
        setSettings(settings);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, token]);


  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return {
    settings,
    isLoading,
  };
}
