import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

export interface Props {
  record: { id: number };
  history: {
    push: (route: string) => void;
  };
}

export const NotificationSettingsButton = (props: any) => {
  const { record, history: { push } }: Props = props;
  return (<MuiButton onClick={() => push(`snd-companies/notifications/${record.id}`)}><NotificationsIcon /></MuiButton>);
};
