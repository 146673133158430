import React from 'react';
import styled from 'styled-components';

import { KeyValueList, View, createAddressString } from '@adac/core-view';
import { __, getFullName, EscalationData } from '@adac/core-model';


const UserDataList = styled(View)`
  & > * {
    margin-bottom: 64px;
  }
`;

export default ({ customerData }: { customerData: EscalationData }) => (
  !customerData ? null : (
    <UserDataList>
      <KeyValueList
        keyValuePairs={[
          {
            key: __('Name'),
            value: getFullName(customerData),
          },
          {
            key: __('Used Address'),
            value: createAddressString(customerData),
          },
        ].concat(
          customerData.customerPhone? {
            key: __('Customer Phone'),
            value: customerData.customerPhone,
          }: [],
        ).concat(
          customerData.description? {
            key: __('Description'),
            value: customerData.description,
          } : [],
        ).concat(
          customerData.productType? {
            key: __('ProductType'),
            value: customerData.productType,
          } : [],
        )}
      />
    </UserDataList>
  )
);
