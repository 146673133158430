/* eslint-disable @typescript-eslint/no-explicit-any */
import { SET_BROWSER_NOTIFICATION } from '../actions/setBrowserNotification';
import { NotificationType } from '../components/admin/BrowserNotification';

export default (previousState = null, { type, payload }: { type: string; payload: NotificationType }) => {
  if (type === SET_BROWSER_NOTIFICATION) {
    return payload;
  }
  return previousState;
};
