import {
  AuthenticationMode, AvailableNotificationSettingValues as Channel, SignInChannelOptions, getApiRoutes,
} from '@adac/core-model';
import { request } from '@adac/core-view';

interface AuthNotificationFields {
  [AuthenticationMode.SIGN_IN_CHANNEL]: Channel.SMS | Channel.EMAIL;
}

export const getSignInUserSetting = async (userId: number, token: string) => {
  const url = getApiRoutes().admin.settings.notification.getSignInUserSetting(userId);
  const res = await request<AuthNotificationFields>(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

interface UpdateUserSetting {
  settings: {
    [AuthenticationMode.SIGN_IN_CHANNEL]: SignInChannelOptions;
  },
  phone?: string;
}

export const updateSignInUserSetting = async (userId: number, token: string, data: UpdateUserSetting) => {
  const url = getApiRoutes().admin.settings.notification.updateSignInUserSetting(userId);
  await request<void, UpdateUserSetting >(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
