import { useEffect } from 'react';
import { connect } from 'react-redux';

import { User } from '@adac/core-model';
import { setBlockedUsersAction, useBlockedUsersAction } from '../../actions/setBlockedUsers';

interface UserBlockListenerProps {
  setBlockedUsers: (data: User[] | null) => void;
}

const UserBlockListener = ({ setBlockedUsers }: UserBlockListenerProps) => {
  const { get: getAndSetBlockedUsers } = useBlockedUsersAction(setBlockedUsers);

  useEffect(() => {
    getAndSetBlockedUsers();
  }, [getAndSetBlockedUsers]);

  return null;
};

export default connect(null, {
  setBlockedUsers: setBlockedUsersAction,
})(UserBlockListener);
