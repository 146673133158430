import styled from 'styled-components';
import { View, SubTitle } from '@adac/core-view';

export const FormStyles = styled(View)`
  &>* {
    width: 50%;
  }
  display: grid;
  place-items: center;

  ${SubTitle} {
    margin-top: 40px;
  }
`;
export const FormLayout = styled(View)`
overflow: auto;

&>* {
  margin: 24px;
}
`;
export const TitleArea = styled(View)`
margin-left: 24px !important;
margin-top: 16px;

display: flex;
align-items: center;
`;
export const TitleAreaText = styled(View)`
margin-left: 16px;
`;
