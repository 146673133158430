import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import {
  getApiRoutes, __,
} from '@adac/core-model';
import DriverIcon from '@material-ui/icons/DriveEta';

export interface Props {
  record: { id: number };
  history: {
    push: (route: string) => void;
  };
}

// props are being populated by DataGrid component with props injection
export const CompanyDriverListButton = (props: any) => {
  const { record, history: { push } }: Props = props;
  return (<MuiButton onClick={() => push(getApiRoutes().companyAdmin.editDriversList(record.id))}><DriverIcon />{__('List Drivers')}</MuiButton>);
};
