/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';

import {
  Children, __,
} from '@adac/core-model';
import {
  View, Button, Divider, FullWidthLayout, Title, Text,
} from '@adac/core-view';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  TitleArea, TitleAreaText,
} from './FormStyles';


const Styles = styled(View)`
  &>* {
    margin: 16px 0;
  }
`;

interface TitleInfoProps {
  id?: string | number;
  title?: string;
}


const TitleInfo = ({ title, id }: TitleInfoProps) => (
  <TitleArea>
    <ArrowBack />
    <TitleAreaText>
      <Title>{title || __('New company')}</Title>
      {id && <Text>({id})</Text>}
    </TitleAreaText>
  </TitleArea>
);

interface HeaderProps {
  goBack?: () => void;
  children?: Children;
}


export const Header = ({ goBack, children }: HeaderProps) => (
  <FullWidthLayout>
    <Button onClick={() => goBack && goBack()}>
      <Styles>
        {children}
        <Divider fullWidth />
      </Styles>
    </Button>
  </FullWidthLayout>
);


interface TitleHeaderProps extends HeaderProps, TitleInfoProps {
}

export const TitleHeader = (props: TitleHeaderProps) => (
  <Header {...props}>
    <TitleInfo {...props} />
  </Header>
);


export default TitleHeader;

export const TitleStyles = styled(View)`
  &>* {
    margin: 24px;
  }
`;

export const SimpleHeader = ({ title, children, ...props }: any) => (
  <Header {...props}>
    <TitleStyles>
      <Title>{title}</Title>
      {children}
    </TitleStyles>
  </Header>
);
