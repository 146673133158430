/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
} from 'react-admin';

import {
  createRequestForGetList,
  createRequestForGetOne,
  createRequestForUpdate,
  createRequestForCreate,
  createRequestForDelete,
} from './requestCreators';

import {
  fetchResource,
} from './responseHandlers';

/**
* Maps react-admin queries to my REST API
*
* @param {string} type Request type, e.g GET_LIST
* @param {string} resource Resource name, e.g. "posts"
* @param {Object} payload Request parameters. Depends on the request type
* @returns {Promise} the Promise for a data response
*/
export default (type: string, resource: string, params: any) => {
  let request: { url: string; options: any };

  switch (type) {
    case GET_LIST: {
      request = createRequestForGetList(resource, params);
      break;
    }
    case GET_ONE:
      request = createRequestForGetOne(resource, params);
      break;
    case CREATE:
      request = createRequestForCreate(resource, params);
      break;
    case UPDATE:
      request = createRequestForUpdate(resource, params);
      break;
    case DELETE:
      request = createRequestForDelete(resource, params);
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  // console.debug('fetchResource', type, request.url, resource, request.options, params);
  return fetchResource(type, request.url, resource, request.options, params);
};
